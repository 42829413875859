import React, { createContext, useState, useEffect, useContext } from 'react';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    let interval;

    if (timerActive && timeLeft !== null) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime && prevTime.minutes === 0 && prevTime.seconds === 0) {
            clearInterval(interval);
            setTimerActive(false); // Stop the timer
            return null; // Reset the timer
          }

          if (prevTime) {
            const newSeconds = prevTime.seconds === 0 ? 59 : prevTime.seconds - 1;
            const newMinutes = prevTime.seconds === 0 ? prevTime.minutes - 1 : prevTime.minutes;

            return { minutes: newMinutes, seconds: newSeconds };
          }

          return prevTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerActive, timeLeft]);

  const startTimer = (durationInMinutes) => {
      setTimeLeft({ minutes: durationInMinutes, seconds: 0 });
      setTimerActive(true);
  };

  const stopTimer = () => {
    setTimerActive(false);
    setTimeLeft(null);
  };

  return (
    <TimerContext.Provider value={{ timeLeft, timerActive, startTimer, stopTimer }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
