import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FlightProvider } from './context/FlightContext';
import { OnwardFlightProvider } from './context/OnwardFlightContext';
import { ReturnFlightProvider } from "./context/ReturnFlightContext";
import { SearchProvider } from "./context/SearchContext";
import { SidebarProvider } from "./context/SidebarContext";
import { UserProvider } from "./context/UserContext";
import { TimerProvider } from "./context/TimerContext";
import Spinner from "./components/Spinner";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
      <TimerProvider>
        <SidebarProvider>
          <FlightProvider>
            <OnwardFlightProvider>
              <ReturnFlightProvider>
                <SearchProvider>
                  <Suspense fallback={<Spinner />}></Suspense>
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </SearchProvider>
              </ReturnFlightProvider>
            </OnwardFlightProvider>
          </FlightProvider>
        </SidebarProvider>
      </TimerProvider>
    </UserProvider>
);

reportWebVitals();
