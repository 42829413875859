import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useReturnFlight } from "./context/ReturnFlightContext";
import { useSearch } from "./context/SearchContext";

function FlightInformation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const [activeTab, setActiveTab] = useState("itinerary");
  const { search } = useSearch();
  const [mySearch] = useState(search);
  const { noOfAdult, noOfChildren, noOfInfant } = mySearch;
  

  const { returnFlight } = useReturnFlight();
  const [returnFlights] = useState(returnFlight);

  const singleReturnFlight = returnFlights.find(
    (item) => item.airInfo[0].fareSourceCode === data.fareSourceCode
  );

  function formatTime(time) {
    const [hour, minute] = time.split(":").map(Number);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12;
    const period = isPM ? "PM" : "AM";

    return `${formattedHour}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function calculateFlightDuration() {
    const duration = data.flightDuration;
    const hoursMatch = duration.match(/(\d+)H/);
    const minutesMatch = duration.match(/(\d+)M/);

    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    return { hours, minutes };
  }

  const { hours, minutes } = calculateFlightDuration();

  function calculateReturnFlightDuration() {
    if (singleReturnFlight?.airInfo?.[0]) {
      const duration = singleReturnFlight.airInfo[0].flightDuration;
      const hoursMatch = duration.match(/(\d+)H/);
      const minutesMatch = duration.match(/(\d+)M/);

      const returnHours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
      const returnMinutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

      return { returnHours, returnMinutes };
    }

    return { returnHours: 0, returnMinutes: 0 };
  }

  const { returnHours, returnMinutes } = calculateReturnFlightDuration();

  function formatNumber(amount, decimals = 2) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(amount);
  }

  const routeToCheckout = () => {
    navigate("/checkout", { state: { data } });
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },[]);

    
  return (
    <div className="flex flex-col bg-gray-100/10 md:pt-8">
      <div className="flex flex-col lg:flex-row container mx-auto max-w-6xl gap-4 px-4 py-8">
        {/* Left Column with Multiple Cards */}
        <div className="flex flex-col gap-4 lg:w-2/3">
          {/* Card 1: Flexibility Message */}
          <div className="flex items-center gap-2 p-6 rounded-lg border border-gray-200">
            <div className="flex items-center mb-2">
              <img src="/res/img/no_change_fee.svg" className="w-18" alt="" />
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-xl font-semibold">
                Stay flexible with no change fees
              </h3>
              <p className="text-gray-600">
                We recommend booking a flight with no change fees in case your
                plans change.
              </p>
              <button className="text-blue-500 font-semibold mt-2">
                Change flights
              </button>
            </div>
          </div>

          <div className="hayaanmqmz">
            <div className="hayaanmqmz-inner">
              <div className="hayaano-C7-tab-list">
                <div
                  role="tab"
                  tabIndex={activeTab === "itinerary" ? 0 : -1}
                  className={`hayaanSTeo ${
                    activeTab === "itinerary" ? "active" : ""
                  }`}
                  id="1b03k8b"
                  aria-controls="20t2242"
                  aria-selected={activeTab === "itinerary"}
                  onClick={() => handleTabClick("itinerary")}
                >
                  Itinerary details
                </div>
                {singleReturnFlight?.airInfo && (
                  <div
                    role="tab"
                    tabIndex={activeTab === "return" ? 0 : -1}
                    className={`hayaanSTeo ${
                      activeTab === "return" ? "active" : ""
                    }`}
                    id="1b03k8b"
                    aria-controls="20t2242"
                    aria-selected={activeTab === "return"}
                    onClick={() => handleTabClick("return")}
                  >
                    Return flight
                  </div>
                )}
              </div>
            </div>
          </div>

          {activeTab === "itinerary" && (
            <div
              role="tabpanel"
              id="20t2242"
              tabIndex={0}
              aria-labelledby="1b03k8b"
            >
              <div className="hayaano-C7-tab-panel-content-wrapper o-C0978-tab-panel-content-wrapper">
                <div className="itinerary-content">
                  <div className="hayaanX3K_ hayaanX3K_-mod-border-radius-large">
                    <div className="hayaanX3K_-header text-left">
                      <span className="hayaanX3K_-header-text" dir="auto">
                        Depart • {formatDate(data.departureDate)}
                      </span>
                      <span className="hayaanX3K_-leg-duration text-xs">
                        {hours} hrs and {minutes} mins
                      </span>
                    </div>
                    <div className="hayaanX3K_-segments">
                      <div className="hayaannAz5">
                        <div className="hayaannAz5-segment">
                          <div className="hayaannAz5-segment-info">
                            <div className="hayaannAz5-carrier-info">
                              <div className="hayaannAz5-carrier-icon">
                                <img
                                  src={data.airlineLogoUrl}
                                  alt={data.airlineName}
                                />
                              </div>
                              <div className="hayaannAz5-carrier-contents">
                                <div className="hayaannAz5-carrier-text">
                                  {data.airlineName}
                                </div>
                                <div>
                                  <div
                                    className="z6uD z6uD-mod-theme-outline z6uD-mod-variant-neutral z6uD-mod-layout-inline z6uD-mod-padding-y-xsmall"
                                    aria-label="Aircraft"
                                  >
                                    {data.flightNumber}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="hayaannAz5-segment-body">
                              <div className="hayaang16k">
                                <div className="hayaang16k-time-graph">
                                  <span className="hayaang16k-dot" />
                                  <span className="hayaang16k-axis" />
                                </div>
                                <div className="hayaang16k-time-info">
                                  <div className="hayaang16k-time-info-text-wrapper">
                                    <span className="hayaang16k-time">
                                      {formatDate(data.departureDate)} at{" "}
                                      {formatTime(data.departureTime)}
                                    </span>
                                    <div className="hayaang16k-location-block">
                                      <span className="hayaang16k-station">
                                        • {data.origin}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="hayaannAz5-duration-row">
                                <div className="hayaannAz5-graph-icon">
                                  <svg
                                    viewBox="0 0 200 200"
                                    width="1.25em"
                                    height="1.25em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hayaannAz5-eq-icon"
                                    role="presentation"
                                  >
                                    <path d="M178.081 41.973c-2.681 2.663-16.065 17.416-28.956 30.221c0 107.916 3.558 99.815-14.555 117.807l-14.358-60.402l-14.67-14.572c-38.873 38.606-33.015 8.711-33.015 45.669c.037 8.071-3.373 13.38-8.263 18.237L50.66 148.39l-30.751-13.513c10.094-10.017 15.609-8.207 39.488-8.207c8.127-16.666 18.173-23.81 26.033-31.62L70.79 80.509L10 66.269c17.153-17.039 6.638-13.895 118.396-13.895c12.96-12.873 26.882-27.703 29.574-30.377c7.745-7.692 28.017-14.357 31.205-11.191c3.187 3.166-3.349 23.474-11.094 31.167zm-13.674 42.469l-8.099 8.027v23.58c17.508-17.55 21.963-17.767 8.099-31.607zm-48.125-47.923c-13.678-13.652-12.642-10.828-32.152 8.57h23.625l8.527-8.57z" />
                                  </svg>
                                </div>
                                <div className="hayaannAz5-duration-text">
                                  {data.transitFlight &&
                                    data.transitFlight.map((item, index) => {
                                      function calculateStopDuration() {
                                        const duration = item.layoverDuration;
                                        const hoursMatch =
                                          duration.match(/(\d+)H/);
                                        const minutesMatch =
                                          duration.match(/(\d+)M/);

                                        const hors = hoursMatch
                                          ? parseInt(hoursMatch[1], 10)
                                          : 0;
                                        const mites = minutesMatch
                                          ? parseInt(minutesMatch[1], 10)
                                          : 0;

                                        return { hors, mites };
                                      }

                                      const { hors, mites } =
                                        calculateStopDuration();

                                      return (
                                        <div
                                          className="hayaannAz5-segment-body"
                                          key={index}
                                        >
                                          <div className="hayaannAz5-carrier-info">
                                            <div className="hayaannAz5-carrier-icon">
                                              <img
                                                src={item.airlineLogo}
                                                alt={item.airlineName}
                                                className="w-8"
                                              />
                                            </div>
                                            <div className="hayaannAz5-carrier-contents">
                                              <div className="hayaannAz5-carrier-text">
                                                {item.airlineName}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="hayaang16k">
                                            <div className="hayaang16k-time-graph">
                                              <span className="hayaang16k-dot" />
                                              <span className="hayaang16k-axis" />
                                            </div>
                                            <div className="hayaang16k-time-info">
                                              <div className="hayaang16k-time-info-text-wrapper flex flex-wrap items-center text-left">
                                                <span className="hayaang16k-time text-sm">
                                                  <span className="text-xs">
                                                    Arrive at
                                                  </span>{" "}
                                                  {formatTime(
                                                    item.arrivalDateTime
                                                  )}
                                                </span>
                                                <div className="hayaang16k-location-block">
                                                  <span className="hayaang16k-station">
                                                    {item.airportCode}-
                                                    {item.city}•{item.country}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="hayaannAz5-duration-row">
                                            <div className="hayaannAz5-graph-icon">
                                              <img
                                                src="/res/svg/plane-landing.svg"
                                                alt=""
                                                viewBox="0 0 200 200"
                                                width="1.25em"
                                                height="1.25em"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="hayaannAz5-eq-icon"
                                                role="presentation"
                                              />
                                            </div>
                                            <div className="hayaannAz5-duration-text flex gap-1">
                                              <span className="text-xs">
                                                Layover for
                                              </span>
                                              <span className="text-xs font-bold">
                                                {hors} hrs and {mites} mins
                                              </span>
                                            </div>
                                          </div>
                                          <div className="hayaang16k">
                                            <div className="hayaang16k-time-graph">
                                              <span className="hayaang16k-axis" />
                                              <span className="hayaang16k-dot" />
                                            </div>
                                            <div className="hayaang16k-time-info hayaang16k-incoming">
                                              <div className="hayaang16k-time-info-text-wrapper text-left flex">
                                                <span className="hayaang16k-time">
                                                  <img
                                                    src="/res/svg/takeoff-the-plane.svg"
                                                    alt=""
                                                    viewBox="0 0 200 200"
                                                    width="1.25em"
                                                    height="1.25em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="hayaannAz5-eq-icon"
                                                    role="presentation"
                                                  />
                                                </span>
                                                <div className="hayaang16k-location-block">
                                                  <span className="hayaang16k-station flex items-center gap-2 font-bold">
                                                    <span className="text-xs">
                                                      Depart at
                                                    </span>
                                                    <span className="text-sm">
                                                      {formatTime(
                                                        item.departureDateTime
                                                      )}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="hayaannAz5-segment-extras-wrapper" />
                                        </div>
                                      );
                                    })}
                                  {data.totalStops === 0 && (
                                    <span className="text-xs">
                                      {hours} hrs and {minutes} mins
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="hayaang16k">
                                <div className="hayaang16k-time-graph">
                                  <span className="hayaang16k-axis" />
                                  <span className="hayaang16k-dot" />
                                </div>
                                <div className="hayaang16k-time-info hayaang16k-incoming">
                                  <div className="hayaang16k-time-info-text-wrapper">
                                    <span className="hayaang16k-time">
                                      {formatDate(data.arrivalDate)} at{" "}
                                      {formatTime(data.arrivalTime)}
                                    </span>
                                    <div className="hayaang16k-location-block">
                                      <span className="hayaang16k-station">
                                        • {data.destination}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="hayaannAz5-segment-extras-wrapper" />
                            </div>
                          </div>
                          <div className="hayaannAz5-segment-amenities" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'return' &&
          <div
            role="tabpanel"
            id="20t2242"
            tabIndex={0}
            aria-labelledby="1b03k8b"
          >
            <div className="hayaano-C7-tab-panel-content-wrapper o-C0978-tab-panel-content-wrapper">
              <div className="itinerary-content">
                <div className="hayaanX3K_ hayaanX3K_-mod-border-radius-large">
                  <div className="hayaanX3K_-header text-left">
                    <span className="hayaanX3K_-header-text" dir="auto">
                      Depart • {formatDate(singleReturnFlight?.airInfo[0]?.departureDate)}
                    </span>
                    <span className="hayaanX3K_-leg-duration text-xs">
                      {returnHours} hrs and {returnMinutes} mins
                    </span>
                  </div>
                  <div className="hayaanX3K_-segments">
                    <div className="hayaannAz5">
                      <div className="hayaannAz5-segment">
                        <div className="hayaannAz5-segment-info">
                          <div className="hayaannAz5-carrier-info">
                            <div className="hayaannAz5-carrier-icon">
                              <img
                                src={singleReturnFlight?.airInfo[0]?.airlineLogoUrl}
                                alt={singleReturnFlight?.airInfo[0]?.airlineName}
                              />
                            </div>
                            <div className="hayaannAz5-carrier-contents">
                              <div className="hayaannAz5-carrier-text">
                                {singleReturnFlight?.airInfo[0]?.airlineName}
                              </div>
                              <div>
                                <div
                                  className="z6uD z6uD-mod-theme-outline z6uD-mod-variant-neutral z6uD-mod-layout-inline z6uD-mod-padding-y-xsmall"
                                  aria-label="Aircraft"
                                >
                                  {singleReturnFlight?.airInfo[0]?.flightNumber}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="hayaannAz5-segment-body">
                            <div className="hayaang16k">
                              <div className="hayaang16k-time-graph">
                                <span className="hayaang16k-dot" />
                                <span className="hayaang16k-axis" />
                              </div>
                              <div className="hayaang16k-time-info">
                                <div className="hayaang16k-time-info-text-wrapper">
                                  <span className="hayaang16k-time">
                                    {formatDate(singleReturnFlight?.airInfo[0]?.departureDate)} at{" "}
                                    {formatTime(singleReturnFlight?.airInfo[0]?.departureTime)}
                                  </span>
                                  <div className="hayaang16k-location-block">
                                    <span className="hayaang16k-station">
                                      • {singleReturnFlight?.airInfo[0]?.origin}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="hayaannAz5-duration-row">
                              <div className="hayaannAz5-graph-icon">
                                <svg
                                  viewBox="0 0 200 200"
                                  width="1.25em"
                                  height="1.25em"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="hayaannAz5-eq-icon"
                                  role="presentation"
                                >
                                  <path d="M178.081 41.973c-2.681 2.663-16.065 17.416-28.956 30.221c0 107.916 3.558 99.815-14.555 117.807l-14.358-60.402l-14.67-14.572c-38.873 38.606-33.015 8.711-33.015 45.669c.037 8.071-3.373 13.38-8.263 18.237L50.66 148.39l-30.751-13.513c10.094-10.017 15.609-8.207 39.488-8.207c8.127-16.666 18.173-23.81 26.033-31.62L70.79 80.509L10 66.269c17.153-17.039 6.638-13.895 118.396-13.895c12.96-12.873 26.882-27.703 29.574-30.377c7.745-7.692 28.017-14.357 31.205-11.191c3.187 3.166-3.349 23.474-11.094 31.167zm-13.674 42.469l-8.099 8.027v23.58c17.508-17.55 21.963-17.767 8.099-31.607zm-48.125-47.923c-13.678-13.652-12.642-10.828-32.152 8.57h23.625l8.527-8.57z" />
                                </svg>
                              </div>
                              <div className="hayaannAz5-duration-text">
                                {singleReturnFlight?.airInfo[0]?.transitFlight &&
                                  singleReturnFlight?.airInfo[0]?.transitFlight.map((item, index) => {
                                    function calculateStopDuration() {
                                      const duration = item.layoverDuration;
                                      const hoursMatch =
                                        duration.match(/(\d+)H/);
                                      const minutesMatch =
                                        duration.match(/(\d+)M/);

                                      const hors = hoursMatch
                                        ? parseInt(hoursMatch[1], 10)
                                        : 0;
                                      const mites = minutesMatch
                                        ? parseInt(minutesMatch[1], 10)
                                        : 0;

                                      return { hors, mites };
                                    }

                                    const { hors, mites } =
                                      calculateStopDuration();

                                    return (
                                      <div
                                        className="hayaannAz5-segment-body"
                                        key={index}
                                      >
                                        <div className="hayaannAz5-carrier-info">
                                          <div className="hayaannAz5-carrier-icon">
                                            <img
                                              src={item.airlineLogo}
                                              alt={item.airlineName}
                                              className="w-8"
                                            />
                                          </div>
                                          <div className="hayaannAz5-carrier-contents">
                                            <div className="hayaannAz5-carrier-text">
                                              {item.airlineName}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="hayaang16k">
                                          <div className="hayaang16k-time-graph">
                                            <span className="hayaang16k-dot" />
                                            <span className="hayaang16k-axis" />
                                          </div>
                                          <div className="hayaang16k-time-info">
                                            <div className="hayaang16k-time-info-text-wrapper flex flex-wrap items-center text-left">
                                              <span className="hayaang16k-time text-sm">
                                                <span className="text-xs">
                                                  Arrive at
                                                </span>{" "}
                                                {formatTime(
                                                  item.arrivalDateTime
                                                )}
                                              </span>
                                              <div className="hayaang16k-location-block">
                                                <span className="hayaang16k-station">
                                                  {item.airportCode}-{item.city}
                                                  •{item.country}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="hayaannAz5-duration-row">
                                          <div className="hayaannAz5-graph-icon">
                                            <img
                                              src="/res/svg/plane-landing.svg"
                                              alt=""
                                              viewBox="0 0 200 200"
                                              width="1.25em"
                                              height="1.25em"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="hayaannAz5-eq-icon"
                                              role="presentation"
                                            />
                                          </div>
                                          <div className="hayaannAz5-duration-text flex gap-1">
                                            <span className="text-xs">
                                              Layover for
                                            </span>
                                            <span className="text-xs font-bold">
                                              {hors} hrs and {mites} mins
                                            </span>
                                          </div>
                                        </div>
                                        <div className="hayaang16k">
                                          <div className="hayaang16k-time-graph">
                                            <span className="hayaang16k-axis" />
                                            <span className="hayaang16k-dot" />
                                          </div>
                                          <div className="hayaang16k-time-info hayaang16k-incoming">
                                            <div className="hayaang16k-time-info-text-wrapper text-left flex">
                                              <span className="hayaang16k-time">
                                                <img
                                                  src="/res/svg/takeoff-the-plane.svg"
                                                  alt=""
                                                  viewBox="0 0 200 200"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="hayaannAz5-eq-icon"
                                                  role="presentation"
                                                />
                                              </span>
                                              <div className="hayaang16k-location-block">
                                                <span className="hayaang16k-station flex items-center gap-2 font-bold">
                                                  <span className="text-xs">
                                                    Depart at
                                                  </span>
                                                  <span className="text-sm">
                                                    {formatTime(
                                                      item.departureDateTime
                                                    )}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="hayaannAz5-segment-extras-wrapper" />
                                      </div>
                                    );
                                  })}
                                {data.totalStops === 0 && (
                                  <span className="text-xs">
                                    {hours} hrs and {minutes} mins
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="hayaang16k">
                              <div className="hayaang16k-time-graph">
                                <span className="hayaang16k-axis" />
                                <span className="hayaang16k-dot" />
                              </div>
                              <div className="hayaang16k-time-info hayaang16k-incoming">
                                <div className="hayaang16k-time-info-text-wrapper">
                                  <span className="hayaang16k-time">
                                    {formatDate(singleReturnFlight?.airInfo[0]?.arrivalDate)} at{" "}
                                    {formatTime(singleReturnFlight?.airInfo[0]?.arrivalTime)}
                                  </span>
                                  <div className="hayaang16k-location-block">
                                    <span className="hayaang16k-station">
                                      • {singleReturnFlight?.airInfo[0]?.destination}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="hayaannAz5-segment-extras-wrapper" />
                          </div>
                        </div>
                        <div className="hayaannAz5-segment-amenities" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}

          <div className="p-6 flex flex-col items-start rounded-lg border border-gray-200">
            <h3 className="text-xl font-semibold">Your fare: Economy</h3>

            {data.baggageInfo[0].allowedBaggage && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 11.917 9.724 16.5 19 7.5"
                  />
                </svg>

                <div className="flex flex-col items-start">
                  <p>Carry-on bag included</p>
                </div>
              </div>
            )}

            {data.baggageInfo[0].allowedBaggage && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clipRule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>

                <div className="flex flex-col items-start">
                  <p>1st checked bag for a fee</p>
                </div>
              </div>
            )}

            {data.fareInfo[0].changeAllowed && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clipRule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>

                <div className="flex flex-col items-start">
                  <p>Change fee applies</p>
                </div>
              </div>
            )}

            {data.fareInfo[0].refundAllowed && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 11.917 9.724 16.5 19 7.5"
                  />
                </svg>

                <div className="flex flex-col items-start">
                  <p>Non-refundable</p>
                </div>
              </div>
            )}
          </div>

          <div className="p-6 flex flex-col items-start rounded-lg border border-gray-200">
            <h3 className="text-xl font-semibold">Bags</h3>

            {data.baggageInfo[0].allowedBaggage && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 11.917 9.724 16.5 19 7.5"
                  />
                </svg>

                <div className="flex flex-col items-start">
                  <p>Carry-on bag included</p>
                </div>
              </div>
            )}

            {data.baggageInfo[0].allowedBaggage && (
              <div className="flex gap-2">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clipRule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>

                <div className="flex flex-col items-start">
                  <p>1st checked bag for a fee</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column with Sticky Price Summary Card */}
        <div className="lg:w-1/3">
          <div className="p-6 rounded-lg border border-gray-200 sticky top-4">
            <dl className="flex items-center justify-between gap-4 border-b border-dashed border-gray-300 pb-4 dark:border-gray-700">
              <div  className="text-left flex text-sm">
                <p>
                  Please make your payment within the next <strong>20 minutes</strong> to keep this session active 
                </p>
                <svg
                  className="w-10 h-10 text-blue-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </dl>
            <div className="space-y-4 rounded-lg dark:border-gray-700 dark:bg-gray-800 sm:p-6">
              <p className="text-xl font-semibold text-gray-900 dark:text-white text-left">
                Price Details ({data.fareInfo[0].currency})
              </p>

              <div className="space-y-4">
                <div className="space-y-2">
                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                      Travelers
                    </dt>
                  </dl>

                  <dl className="flex items-center text-xs justify-between gap-4">
                    <dd className="font-medium text-gray-900">
                     {parseInt(noOfAdult)} {parseInt(noOfAdult) > 1 ? 'Adults' : 'Adult'}
                    </dd>
                    <dd className="font-medium text-gray-900">
                    {parseInt(noOfChildren)} {parseInt(noOfAdult) > 1 ? 'Children' : 'Child'}
                    </dd>
                    <dd className="font-medium text-gray-900">
                      {parseInt(noOfInfant)} {parseInt(noOfAdult) > 1 ? 'Infants' : 'Infant'}
                    </dd>
                  </dl>
                </div>

                <dl className="flex items-center justify-between gap-4 border-t border-dashed border-gray-300 pt-4 dark:border-gray-700">
                  <dt className="text-lg font-bold text-gray-900 dark:text-white">
                    Total Price
                  </dt>
                  <dd className="text-base font-bold text-gray-900 dark:text-white">
                    <div className="hayaanf8F1-price-text text-sm">
                      {data.fareInfo[0].currency}
                    </div>
                    {formatNumber(data.fareInfo[0].totalAmount)}
                  </dd>
                </dl>

                <dl className="flex items-center justify-between gap-4 border-t border-dashed border-gray-300 pt-4 dark:border-gray-700">
                  <div  className="text-left flex text-sm">
                    <p>
                      Rates are quoted in <strong>{data.fareInfo[0].currency}</strong>. Some airlines may charge <strong>baggage</strong> fee. Your credit/debit card may be billed in multiple charges totaling the final total price. Promo Code is off our service fees.
                    </p>
                  </div>
                </dl>
              </div>

              <p
                onClick={routeToCheckout}
                className="flex cursor-pointer w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Proceed to Checkout
              </p>

              <div className="flex items-center justify-center gap-2">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {" "}
                  or{" "}
                </span>
                <Link
                  to="/search-results"
                  className="inline-flex items-center cursor-pointer gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
                >
                  Change flight
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </Link>
              </div>
            </div>

            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
              <div className="flex items-center gap-2 rounded-lg">
                <div className="flex items-center mb-2">
                  <img
                    src="/res/svg/fee_cancellation.svg"
                    className="w-20"
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-start text-left">
                  <h3 className="text-xl font-semibold">
                    {data.fareInfo[0].refundAllowed !== true && "No"} Free
                    cancellation
                  </h3>
                  {data.fareInfo[0].refundAllowed && (
                    <p className="text-gray-600 gap-1 flex flex-wrap">
                      There's no fee to cancel within 24 hours of booking.
                      Cancellation fee is:
                      <span className="hayaanf8F1-price-text text-sm">
                        {data.fareInfo[0].currency}
                      </span>
                      {formatNumber(data.fareInfo[0].refundPenaltyAmount)}
                    </p>
                  )}
                </div>
              </div>
            </div>


            {data.fareInfo[0].changeAllowed && (
            <div className="mt-4 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
              <div className="flex items-center gap-2 rounded-lg">
                <div className="flex items-center mb-2">
                  <img
                    src="/res/img/change.webp"
                    className="w-20"
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-start text-left">
                  
                    <p className="text-gray-600 gap-1 flex flex-wrap font-semibold">
                      Change fee is:
                      <span className="hayaanf8F1-price-text text-xs gap-1">
                        {data.fareInfo[0].currency}
                        <span className="text-sm font-bold"> {formatNumber(data.fareInfo[0].changePenaltyAmount)}</span>
                      </span>
                    </p>
                </div>
              </div>
            </div>
                 )}


          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightInformation;
