import React, { useCallback, useRef, useEffect } from "react";
import "./Adds.css";
import addVid from "../assets/vid/vid.mp4"

const AddsModal = ({ handleViewAdd }) => {
  const sectionRef = useRef(null);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleViewAdd(e);
    },
    [handleViewAdd]
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (sectionRef.current && !sectionRef.current.contains(e.target)) {
        handleClick(e);
      }
    },
    [handleClick]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="adds-background absolute top-0 left-0 p-4">
      <section
        className="text-gray-600 body-font relative mx-auto"
        ref={sectionRef}
      >
        <div className="container max-w-4xl mx-auto flex flex-col gap-6 px-5 pb-24 pt-6 md:flex-row rounded-md bg-white shadow-md">
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center gap-6">
            <h1 className="title-font text-xl lg:text-4xl font-medium text-gray-900">
              Grab Your Tickets
              <br className="hidden lg:inline-block" />
              Before They’re Gone! ✈️
            </h1>
            <p className="leading-relaxed text-sm lg:text-xl">
              Jet-set in style with unbeatable deals on flights to your dream
              destinations. Don’t let the trendsetters snag your seat—secure
              your trip today before it’s too late!
            </p>
            <div className="flex justify-center gap-6">
              <button
                className="inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded lg:text-lg"
                onClick={handleClick}
              >
                Close
              </button>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mx-auto lg:mx-0">
            <section className="relative lg:min-h-full flex flex-col items-center justify-center text-center text-white">
              <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden rounded-md">
                <video
                  className="min-w-full min-h-full absolute object-cover"
                  src={addVid}
                  type="video/mp4"
                  autoPlay
                  muted
                  loop
                />
              </div>
              <div className="video-content space-y-6 z-10 h-[20vh] md:h-[30vh]"></div>
            </section>
          </div>
        </div>

        <div className="spinner-icon left-[45%]"></div>
      </section>
    </div>
  );
};

export default AddsModal;
