import React from 'react';
import { Link } from "react-router-dom";
import { useTimer } from '../context/TimerContext';

function Header() {
  const { timeLeft, timerActive } = useTimer();

  const TimeUnit = ({ value, label }) => (
    <div className="timer">
      <div className="rounded-xl min-w-[60px] md:min-w-[80px] flex items-center justify-center flex-col gap-0 aspect-square px-1 md:px-1.5">
        <h3 className="font-manrope font-semibold text-md text-black text-center">
          {value.toString().padStart(2, '0')}
        </h3>
        <p className="text-xs font-inter capitalize font-normal text-indigo-600 text-center w-full">
          {label}
        </p>
      </div>
    </div>
  );

  const Separator = () => (
    <h3 className="font-manrope font-semibold text-lg md:text-2xl text-gray-900">:</h3>
  );

  return (
    <header className="w-full shadow-sm" style={{ backgroundColor: '#fecf0d' }}>
      <div className="fixed w-full z-50 py-4" style={{ backgroundColor: '#fecf0d' }}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between h-auto md:h-20">
            {/* Logo Section */}
            <div className="w-full md:w-auto flex justify-between items-center py-4 md:py-0">
              <Link to="/" className="flex-shrink-0">
                <img src="/logo.jpeg" className="w-[120px] md:w-[220px]" alt="Logo" />
              </Link>

              {timerActive && timeLeft ? (
              <div className="w-full md:w-auto ml-auto mr-4 md:hidden">

                  <Link to="/search-results" className="block group relative">
                    <div className="flex items-center justify-center gap-1">
                      <TimeUnit value={timeLeft.minutes} label="Min" />
                      <Separator />
                      <TimeUnit value={timeLeft.seconds} label="Sec" />
                    </div>
                    <span className="invisible group-hover:visible absolute top-6 left-1/2 -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded whitespace-nowrap">
                    View Your flights
                    </span>
                  </Link>
              </div>
              ) : null}

              {/* Mobile Sign In */}
              <div className="md:hidden">
                <Link
                  to="/login"
                  className="flex items-center gap-2 text-gray-700 hover:text-indigo-600"
                >
                  <svg
                    viewBox="0 0 200 200"
                    width="1.25em"
                    height="1.25em"
                    className="w-6 h-6"
                  >
                    <path d="M118.61 188.05c.7-.15 1.36-.36 2.05-.53c2.32-.55 4.62-1.13 6.87-1.85c.51-.17 1-.38 1.51-.55c2.4-.82 4.77-1.7 7.08-2.72c.31-.14.6-.3.91-.44c2.48-1.12 4.91-2.34 7.26-3.67c.14-.08.26-.16.4-.24c2.51-1.44 4.94-2.99 7.28-4.66c.01 0 .03-.02.04-.03C174.97 157.05 190 130.3 190 100c0-49.71-40.29-90-90-90s-90 40.29-90 90c0 30.31 15.03 57.06 37.99 73.36c.01 0 .03.02.04.03c2.35 1.67 4.78 3.22 7.28 4.66c.14.08.26.17.4.24c2.36 1.34 4.79 2.55 7.27 3.68c.31.14.6.3.9.43c2.31 1.01 4.68 1.9 7.08 2.72c.51.17 1 .39 1.51.55c2.25.72 4.55 1.3 6.87 1.85c.69.16 1.35.38 2.05.53c2.28.48 4.61.81 6.95 1.11c.75.1 1.48.26 2.24.34c3.1.32 6.23.5 9.42.5s6.32-.17 9.42-.5c.76-.08 1.49-.24 2.24-.34c2.34-.3 4.67-.63 6.95-1.11zM100 26.36c40.6 0 73.64 33.03 73.64 73.64c0 19.08-7.35 36.43-19.3 49.52c-14.45-12.93-33.42-20.88-54.34-20.88s-39.89 7.95-54.34 20.88c-11.94-13.09-19.3-30.44-19.3-49.52c0-40.6 33.03-73.64 73.64-73.64zM75.45 91.81V71.36c0-1.39.12-2.76.34-4.09C77.74 55.66 87.83 46.82 100 46.82c13.56 0 24.55 10.99 24.55 24.55v20.45c0 13.56-10.99 24.55-24.55 24.55s-24.55-10.99-24.55-24.55z" />
                  </svg>
                </Link>
              </div>
            </div>

            {/* Timer Section */}
            {timerActive && timeLeft ? (
              <div className="w-full md:w-auto ml-auto mr-4 hidden md:flex">

                  <Link to="/search-results" className="block group relative">
                    <div className="flex items-center justify-center gap-1">
                      <TimeUnit value={timeLeft.minutes} label="Min" />
                      <Separator />
                      <TimeUnit value={timeLeft.seconds} label="Sec" />
                    </div>
                    <span className="invisible group-hover:visible absolute top-6 left-1/2 -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded whitespace-nowrap">
                    View Your flights
                    </span>
                  </Link>
              </div>
              ) : null}

            {/* Desktop Sign In */}
            <div className="hidden md:block">
              <Link
                to="/login"
                className="flex items-center gap-2 text-gray-700 hover:text-indigo-600"
              >
                <svg
                  viewBox="0 0 200 200"
                  width="1.25em"
                  height="1.25em"
                  className="w-12 h-12"
                >
                  <path d="M118.61 188.05c.7-.15 1.36-.36 2.05-.53c2.32-.55 4.62-1.13 6.87-1.85c.51-.17 1-.38 1.51-.55c2.4-.82 4.77-1.7 7.08-2.72c.31-.14.6-.3.91-.44c2.48-1.12 4.91-2.34 7.26-3.67c.14-.08.26-.16.4-.24c2.51-1.44 4.94-2.99 7.28-4.66c.01 0 .03-.02.04-.03C174.97 157.05 190 130.3 190 100c0-49.71-40.29-90-90-90s-90 40.29-90 90c0 30.31 15.03 57.06 37.99 73.36c.01 0 .03.02.04.03c2.35 1.67 4.78 3.22 7.28 4.66c.14.08.26.17.4.24c2.36 1.34 4.79 2.55 7.27 3.68c.31.14.6.3.9.43c2.31 1.01 4.68 1.9 7.08 2.72c.51.17 1 .39 1.51.55c2.25.72 4.55 1.3 6.87 1.85c.69.16 1.35.38 2.05.53c2.28.48 4.61.81 6.95 1.11c.75.1 1.48.26 2.24.34c3.1.32 6.23.5 9.42.5s6.32-.17 9.42-.5c.76-.08 1.49-.24 2.24-.34c2.34-.3 4.67-.63 6.95-1.11zM100 26.36c40.6 0 73.64 33.03 73.64 73.64c0 19.08-7.35 36.43-19.3 49.52c-14.45-12.93-33.42-20.88-54.34-20.88s-39.89 7.95-54.34 20.88c-11.94-13.09-19.3-30.44-19.3-49.52c0-40.6 33.03-73.64 73.64-73.64zM75.45 91.81V71.36c0-1.39.12-2.76.34-4.09C77.74 55.66 87.83 46.82 100 46.82c13.56 0 24.55 10.99 24.55 24.55v20.45c0 13.56-10.99 24.55-24.55 24.55s-24.55-10.99-24.55-24.55z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="h-32 md:h-20" />
    </header>
  );
}

export default Header;
